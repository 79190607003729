<template>
  <v-container fluid class="content-wrapper mb-4">
    <!-- <div
    class="overflow-y-auto d-flex flex-column pa-3"
    style="height: 80vh; max-height: 100%"
    >-->
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("SigninGeneral.title") }}</h4>
      <v-btn
        icon
        class="ml-3"
        @click="getGeneralinfo()"
        :class="loadingGeneralData && 'animate__spining'"
        :disabled="loadingGeneralData"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <v-row v-if="loadingGeneralData" row wrap>
      <v-col cols="12">
        <v-card tile class="card-header pa-4">
          <v-skeleton-loader
            type="list-item-two-line, divider, list-item-two-line, divider, list-item-two-line, divider, list-item-two-line"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else row wrap>
      <v-col cols="12">
        <v-card tile class="card-header pa-4">
          <v-row class="d-flex justify-content-between align-items-center">
            <v-col :cols="!generalInfo.voucher_suspend ? '10' : '12'">
              <div class="topic">{{ $t("SigninGeneral.property_name") }}</div>
              <div class="value">{{ generalInfo.name }}</div>
              <!-- <a class="mb-0 text-primary link-btn" @click="closePropertyInfoInfoInfo()">
                {{ $t("SigninGeneral.btn_changeinfo") }}
              </a>-->
            </v-col>
            <v-col v-if="!generalInfo.voucher_suspend" cols="2" align="end">
              <v-icon small @click="editPropertyName(generalInfo.name)"
                >mdi-pencil</v-icon
              >
            </v-col>
            <v-col cols="12">
              <v-divider class="space-divider"></v-divider>
            </v-col>
            <!--  -->
            <v-col :cols="!generalInfo.voucher_suspend ? '10' : '12'">
              <div class="topic">{{ $t("SigninGeneral.property_address") }} :</div>
              <div class="value">
                {{ contact.address_line_1 }}
                {{ contact.address_line_2 }}
                , {{ contact.subdistrict }}, {{ contact.district }},
                {{ contact.province }}, {{ contact.country }},
                {{ contact.post_code }}
              </div>
            </v-col>
            <v-col v-if="!generalInfo.voucher_suspend" cols="2" align="end">
              <v-icon small @click="editPropertyAddress(contact)">mdi-pencil</v-icon>
            </v-col>
            <v-col cols="12">
              <v-divider class="space-divider"></v-divider>
            </v-col>
            <v-col :cols="!generalInfo.voucher_suspend ? '10' : '12'">
              <div class="topic">{{ $t("SigninGeneral.property_location") }} :</div>
              <div class="value">
                {{ location.latitude }},
                {{ location.longitude }}
              </div>
            </v-col>
            <v-col v-if="!generalInfo.voucher_suspend" cols="2" align="end">
              <v-icon
                small
                @click="editPropertyLocation(location.latitude, location.longitude)"
                >mdi-pencil</v-icon
              >
            </v-col>
            <v-col cols="12">
              <v-divider class="space-divider"></v-divider>
            </v-col>
            <v-col cols="12">
              <div class="topic">
                {{ $t("SigninGeneral.property_status") }} :
                <v-progress-circular
                  v-if="loadingHotelStatus"
                  indeterminate
                  color="primary"
                  size="20"
                  class="ml-3"
                ></v-progress-circular>
                <span v-else-if="!loadingHotelStatus">
                  <span
                    class="badge"
                    :class="
                      generalInfo.active &&
                      generalInfo.voucher_status &&
                      !generalInfo.voucher_suspend
                        ? 'badge-success'
                        : generalInfo.active &&
                          !generalInfo.voucher_status &&
                          !generalInfo.voucher_suspend
                        ? 'badge-warning'
                        : 'badge-danger'
                    "
                    >{{ checkHotelStatusFunc() }}</span
                  >
                </span>
              </div>
              <!-- generalInfo.active &&
                  !generalInfo.voucher_status &&
                  !generalInfo.voucher_suspend
                    ? $t("SigninGeneral.voucher_active")
                    :  -->
              <a
                v-if="
                  generalInfo.active &&
                  generalInfo.voucher_status &&
                  !generalInfo.voucher_suspend
                "
                @click="
                  InactiveVoucher(generalInfo.closed_reason, generalInfo.voucher_status)
                "
                class="mb-0 mt-1 text-primary link-btn"
              >
                {{ $t("SigninGeneral.voucher_stopsell") }}
                <v-icon color="#666ee8" small class="mb-1"
                  >mdi-information-outline</v-icon
                >
              </a>
              <a
                v-if="
                  generalInfo.active &&
                  !generalInfo.voucher_status &&
                  !generalInfo.voucher_suspend
                "
                @click="ActiveVoucher(generalInfo.voucher_status)"
                class="mb-0 mt-1 text-primary link-btn"
              >
                {{ $t("SigninGeneral.voucher_active") }}
                <v-icon color="#666ee8" small class="mb-1"
                  >mdi-information-outline</v-icon
                >
              </a>
            </v-col>
            <v-col cols="12">
              <v-divider class="space-divider"></v-divider>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!--------------------  ---------------------->
      <v-col cols="12">{{ $t("SigninGeneral.title_detail") }}</v-col>
      <v-col cols="12" style="margin-top: -10px">
        <v-card tile class="card-header pa-4">
          <v-row class="d-flex justify-content-between align-items-center">
            <v-col cols="12">
              <div class="topic">
                {{ $t("SigninGeneral.title_about_prop") }}
              </div>
              <div style="color: #878787; font-size: 14px">
                {{ $t("SigninGeneral.text_about_prop") }}
              </div>
              <v-textarea
                :disabled="generalInfo.voucher_suspend"
                outlined
                dense
                counter
                class="mt-2"
                maxlength="1200"
                color="#1e9ff2"
                v-model="about_property"
              ></v-textarea>
              <v-alert
                v-if="iconAlert"
                dense
                v-model="alertAboutProperty"
                :color="colorAlert"
                :icon="iconAlert"
                >{{ msgAlert }}</v-alert
              >
              <v-btn
                v-if="!generalInfo.voucher_suspend"
                color="#666ee8"
                dark
                depressed
                @click="updateAboutProperty(about_property)"
                >{{ $t("SigninGeneral.btn_update_info") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!--------------------  ---------------------->
      <v-col cols="12">{{ $t("SigninGeneral.title_selecthost") }}</v-col>
      <v-col cols="12" style="margin-top: -10px">
        <v-card tile class="card-header pa-4">
          <v-row class="d-flex justify-content-between align-items-center">
            <v-col cols="12">
              <div class="topic">
                <p class="detail">{{ $t("SigninGeneral.text_selecthost") }}</p>
                <!-- <p class="detail">
                  หากต้องการข้อมูลเพิ่มเติม โปรดอ่าน
                  <a style="color: #1e9ff2">บทความนี้</a>
                  ในหน้าความช่วยเหลือสำหรับลูกค้า
                </p>-->
              </div>
              <div class="topic mt-4">
                {{ $t("SigninGeneral.option_selecthost") }}
              </div>
              <v-radio-group
                style="margin-top: 5px"
                v-model="professional_business"
                column
                color="#1e9ff2"
                :disabled="generalInfo.voucher_suspend"
              >
                <v-radio value="true" color="#1e9ff2">
                  <template v-slot:label>
                    <div class="mt-2">
                      {{ $t("SigninGeneral.professional_host") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio value="false" color="#1e9ff2">
                  <template v-slot:label>
                    <div class="mt-2">
                      {{ $t("SigninGeneral.non_professional_host") }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-alert
                v-if="iconAlert"
                dense
                v-model="alertProfessional"
                :color="colorAlert"
                :icon="iconAlert"
                >{{ msgAlert }}</v-alert
              >
              <v-btn
                v-if="!generalInfo.voucher_suspend"
                color="#666ee8"
                dark
                depressed
                @click="updateProfessional(professional_business)"
                >{{ $t("SigninGeneral.btn_update_info") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!--------------------  ---------------------->
      <v-col cols="12">{{ $t("SigninGeneral.title_about") }}</v-col>
      <v-col cols="12" style="margin-top: -10px">
        <v-card tile class="card-header pa-4">
          <v-row class="d-flex justify-content-between align-items-center">
            <v-col cols="12">
              <div class="topic">
                <p class="detail">{{ $t("SigninGeneral.text_about") }}</p>
                <!-- <p class="detail">
                  หากต้องการข้อมูลเพิ่มเติม โปรดอ่าน
                  <a style="color: #1e9ff2">บทความนี้</a>
                  ในหน้าความช่วยเหลือสำหรับลูกค้า
                </p>-->
              </div>
            </v-col>
            <v-col cols="12">
              <div>
                <strong>{{ $t("SigninGeneral.question_use_pms") }}</strong>
              </div>
              <v-text-field
                outlined
                dense
                hide-details
                color="#1e9ff2"
                class="mt-1"
                :style="pms_check ? 'background-color: #e0e0e0;' : ''"
                :placeholder="pms_check ? $t('SigninGeneral.un_check') : ''"
                v-model="pms"
                :disabled="pms_check || generalInfo.voucher_suspend"
              ></v-text-field>
              <v-checkbox
                @change="checkPMS()"
                v-model="pms_check"
                style="margin-top: -3px"
                color="#2196F3"
                :disabled="generalInfo.voucher_suspend"
              >
                <template v-slot:label>
                  <div class="mt-2">{{ $t("SigninGeneral.not_use_pms") }}</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" style="margin-top: -3%">
              <div>
                <strong>{{ $t("SigninGeneral.question_use_other") }}</strong>
              </div>
              <v-text-field
                outlined
                dense
                hide-details
                color="#1e9ff2"
                class="mt-1"
                :style="channel_manager_check ? 'background-color: #e0e0e0;' : ''"
                :placeholder="channel_manager_check ? $t('SigninGeneral.un_check') : ''"
                v-model="channel_manager"
                :disabled="channel_manager_check || generalInfo.voucher_suspend"
              ></v-text-field>
              <v-checkbox
                @click="checkChanelManager()"
                v-model="channel_manager_check"
                style="margin-top: -3px"
                color="#2196F3"
                :disabled="generalInfo.voucher_suspend"
              >
                <template v-slot:label>
                  <div class="mt-2">
                    {{ $t("SigninGeneral.not_use_other") }}
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-alert
            v-if="iconAlert"
            dense
            v-model="alertChanel"
            :color="colorAlert"
            :icon="iconAlert"
            >{{ msgAlert }}</v-alert
          >
          <v-btn
            v-if="!generalInfo.voucher_suspend"
            color="#666ee8"
            dark
            depressed
            @click="updateChannel()"
            >{{ $t("SigninGeneral.btn_update_info") }}</v-btn
          >
          <v-btn
            v-if="!generalInfo.voucher_suspend"
            color="#ff9149"
            class="ml-2"
            dark
            depressed
            @click="resetChannel()"
            >{{ $t("SigninGeneral.btn_reset") }}</v-btn
          >
        </v-card>
      </v-col>
      <!--------------------  ---------------------->
      <v-col cols="12">
        <v-btn
          v-if="!generalInfo.voucher_status"
          color="error"
          dark
          depressed
          @click="delProperty()"
          >{{ $t("SigninGeneral.btn_del_property") }}</v-btn
        >
      </v-col>
    </v-row>
    <!-------------------- dialogPropertyName ---------------------->
    <v-dialog v-model="dialogPropertyName" max-width="500px">
      <v-card tile>
        <div class="header-dialog">
          {{ $t("SigninGeneral.edit_name_property") }}
          <v-spacer></v-spacer>
          <v-icon small @click="dialogPropertyName = false">mdi-close</v-icon>
        </div>
        <v-card-text>
          <!-- <span class="mb-2">{{ $t("SigninGeneral.property_name") }}:</span> -->
          <v-text-field
            outlined
            dense
            hide-details
            color="#1e9ff2"
            class="mb-2 mt-4"
            :placeholder="$t('SigninGeneral.placeholder_name')"
            v-model="propertyName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="footer-dialog pt-4 pb-4">
          <v-btn color="#6b6f82" outlined @click="dialogPropertyName = false">
            {{ $t("SigninGeneral.btn_cancel") }}
          </v-btn>
          <v-btn
            color="#666ee8"
            outlined
            :disabled="!propertyName"
            @click="updateProperty('name')"
            >{{ $t("SigninGeneral.btn_save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-------------------- dialogPropertyAddress ---------------------->
    <v-dialog v-model="dialogPropertyAddress" max-width="500px">
      <v-card tile>
        <div class="header-dialog">
          {{ $t("SigninGeneral.edit_address_property") }}
          <v-spacer></v-spacer>
          <v-icon small @click="dialogPropertyAddress = false">mdi-close</v-icon>
        </div>
        <v-card-text>
          <!-- <span class="mb-2">{{ $t("SigninGeneral.property_location") }}:</span>
          <br>-->
          <div class="d-flex flex-column mb-3">
            <span class="mb-2">{{ $t("SignupStep1.location1") }}</span>
            <input
              id="location1"
              type="text"
              class="form-control-signin"
              name
              v-model="address_line_1"
            />
            <span class="error-text-input" v-if="warndatalocation1">{{
              $t("Alert.pleasefilldata")
            }}</span>
          </div>
          <div class="d-flex flex-column mb-3">
            <span class="mb-2">{{ $t("SignupStep1.location2") }}</span>
            <input
              id="location1"
              type="text"
              class="form-control-signin"
              name
              v-model="address_line_2"
            />
          </div>
          <div class="d-flex flex-column mb-3">
            <span class="mb-2">{{ $t("SignupStep1.location7") }}</span>
            <div
              class="dropdown select"
              @click="getfocus('country')"
              @mouseleave="mouseOver('country')"
            >
              <a
                data-toggle="dropdown"
                data-display="static"
                id="country"
                style="height: 2.5rem !important"
              >
                <v-autocomplete
                  v-model="country"
                  :items="itemsCountry"
                  item-text="name"
                  item-value="value"
                  :no-data-text="$t('SignupStep.nodata')"
                  color="#1e9ff2"
                  solo
                  flat
                  dense
                  single-line
                  hide-details
                  autocomplete="off"
                  @mouseenter="getfocus('country')"
                  @mouseleave="mouseOver('country')"
                ></v-autocomplete>
              </a>
            </div>
            <span class="error-text-input" v-if="warndatacountry">{{
              $t("Alert.pleasefilldata")
            }}</span>
          </div>
          <div class="d-flex flex-column mb-3">
            <span class="mb-2">{{ $t("SignupStep1.location5") }}</span>
            <div
              class="dropdown select"
              @click="getfocus('province')"
              @mouseleave="mouseOver('province')"
            >
              <a
                data-toggle="dropdown"
                data-display="static"
                id="province"
                style="height: 2.5rem !important"
              >
                <v-autocomplete
                  v-model="province"
                  :items="itemsProvince"
                  item-text="province"
                  item-value="province_code"
                  :no-data-text="$t('SignupStep.nodata')"
                  color="#1e9ff2"
                  solo
                  flat
                  dense
                  single-line
                  hide-details
                  autocomplete="off"
                  @mouseenter="getfocus('province')"
                  @mouseleave="mouseOver('province')"
                  @change="selectProvinceFunc()"
                ></v-autocomplete>
              </a>
            </div>
            <span class="error-text-input" v-if="warndataprovince">{{
              $t("Alert.pleasefilldata")
            }}</span>
          </div>
          <div class="d-flex flex-column mb-3">
            <span class="mb-2">{{ $t("SignupStep1.location3") }}</span>
            <div
              class="dropdown select"
              @click="getfocus('district')"
              @mouseleave="mouseOver('district')"
            >
              <a
                data-toggle="dropdown"
                data-display="static"
                id="district"
                style="height: 2.5rem !important"
              >
                <v-autocomplete
                  v-model="district"
                  :items="itemsAmphoe"
                  item-text="amphoe"
                  item-value="amphoe_code"
                  :no-data-text="$t('SignupStep.nodata')"
                  color="#1e9ff2"
                  solo
                  flat
                  dense
                  single-line
                  hide-details
                  autocomplete="off"
                  @mouseenter="getfocus('district')"
                  @mouseleave="mouseOver('district')"
                ></v-autocomplete>
              </a>
            </div>
            <span class="error-text-input" v-if="warndatadistrict">{{
              $t("Alert.pleasefilldata")
            }}</span>
          </div>
          <div class="d-flex flex-column mb-3">
            <span class="mb-2">{{ $t("SignupStep1.location4") }}</span>

            <div
              class="dropdown select"
              @click="getfocus('subdistrict')"
              @mouseleave="mouseOver('subdistrict')"
            >
              <a
                data-toggle="dropdown"
                data-display="static"
                id="subdistrict"
                style="height: 2.5rem !important"
              >
                <v-autocomplete
                  v-model="subdistrict"
                  :items="itemsDistrict"
                  item-text="district"
                  item-value="district_code"
                  :no-data-text="$t('SignupStep.nodata')"
                  color="#1e9ff2"
                  solo
                  flat
                  dense
                  single-line
                  hide-details
                  autocomplete="off"
                  @mouseenter="getfocus('subdistrict')"
                  @mouseleave="mouseOver('subdistrict')"
                  @click="selectPostcodeFunc()"
                ></v-autocomplete>
              </a>
            </div>
            <span class="error-text-input" v-if="warndatasubdistrict">{{
              $t("Alert.pleasefilldata")
            }}</span>
          </div>
          <div class="d-flex flex-column mb-3">
            <span class="mb-2">{{ $t("SignupStep1.location6") }}</span>
            <input
              id="postcode"
              type="text"
              class="form-control-signin"
              name
              v-model="post_code"
            />
            <span class="error-text-input" v-if="warndatapostcode">{{
              $t("Alert.pleasefilldata")
            }}</span>
          </div>
        </v-card-text>
        <v-card-actions class="footer-dialog pt-4 pb-4">
          <v-btn color="#6b6f82" outlined @click="dialogPropertyAddress = false">{{
            $t("SigninGeneral.btn_cancel")
          }}</v-btn>
          <v-btn color="#666ee8" outlined @click="updateProperty('address')">
            {{ $t("SigninGeneral.btn_save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-------------------- dialogPropertyLocation ---------------------->
    <v-dialog v-model="dialogPropertyLocation" max-width="500px">
      <v-card tile>
        <div class="header-dialog">
          {{ $t("SigninGeneral.edit_location_property") }}
          <v-spacer />
          <v-icon small @click="dialogPropertyLocation = false">mdi-close</v-icon>
        </div>
        <v-card-text>
          <v-row row wrap class="mt-2">
            <v-col cols="12" style="margin-bottom: -30px">
              <div class="form-box">
                <div class="form-group">
                  <div class="form-group has-button">
                    <gmap-autocomplete
                      @place_changed="setPlace"
                      class="form-control"
                      :placeholder="$t('SigninGeneral.search_location')"
                    ></gmap-autocomplete>
                    <button class="btn btn-searchmap" type="button" @click="searchArea">
                      <span class="icons icon-search"></span>
                    </button>
                  </div>
                </div>
                <div class="form-group">
                  <div class="map h-260">
                    <gmap-map
                      class="my-5 map h-260"
                      :center="center"
                      :zoom="14"
                      @click="selectarea"
                    >
                      <GmapMarker
                        v-for="(m, index) in markers"
                        :key="index"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @dragend="gMapFunc"
                      />
                    </gmap-map>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="3" class="mt-2">
              <span>{{ $t("SigninGeneral.longitude") }}:</span>
            </v-col>
            <v-col cols="9">
              <v-text-field
                outlined
                dense
                hide-details
                color="#1e9ff2"
                class="mb-3"
                placeholder="Longitude"
                v-model="propertyLocation.longitude"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="mt-2">
              <span>{{ $t("SigninGeneral.latitude") }}:</span>
            </v-col>
            <v-col cols="9">
              <v-text-field
                outlined
                dense
                hide-details
                color="#1e9ff2"
                class="mb-3"
                placeholder="Latitude"
                v-model="propertyLocation.latitude"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="footer-dialog pt-4 pb-4">
          <v-btn color="#6b6f82" outlined @click="dialogPropertyLocation = false">{{
            $t("SigninGeneral.btn_cancel")
          }}</v-btn>
          <v-btn color="#666ee8" outlined @click="updateProperty('location')">
            {{ $t("SigninGeneral.btn_save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-------------------- dialogSuspendProperty ---------------------->
    <v-dialog v-model="dialogSuspendProperty" max-width="500px">
      <v-card tile>
        <div class="header-dialog">
          {{ $t("SigninGeneral.voucher_stopsell") }}
          <v-spacer></v-spacer>
          <v-icon small @click="dialogSuspendProperty = false">mdi-close</v-icon>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p>{{ $t("SigninGeneral.text_when_close") }}</p>
              <ul>
                <li>{{ $t("SigninGeneral.text_cant_sell") }}</li>
                <li>{{ $t("SigninGeneral.text_resell") }}</li>
                <li>
                  {{ $t("SigninGeneral.text_manage") }}
                  <!-- และชำระยอดที่ค้างในใบแจ้งหนี้ในระหว่างชั่วคราว -->
                </li>
              </ul>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" style="margin-top: -5%">
              <p>{{ $t("SigninGeneral.text_why") }}</p>
              <v-select
                :items="reasons"
                v-model="voucherReason"
                color="#1e9ff2"
                dense
                outlined
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" style="margin-top: -2%">
              <p>{{ $t("SigninGeneral.text_want") }}</p>
              <v-radio-group row v-model="voucherStatus" color="#1e9ff2">
                <v-radio value="true" color="#1e9ff2">
                  <template v-slot:label>
                    <div class="mt-2">{{ $t("SigninGeneral.yes") }}</div>
                  </template>
                </v-radio>
                <v-radio value="false" color="#1e9ff2">
                  <template v-slot:label>
                    <div class="mt-2">{{ $t("SigninGeneral.no") }}</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="footer-dialog pt-4 pb-4">
          <v-btn color="#6b6f82" outlined @click="dialogSuspendProperty = false">{{
            $t("SigninGeneral.btn_cancel")
          }}</v-btn>
          <v-btn color="#666ee8" outlined @click="updateStatusVoucher">
            {{ $t("SigninGeneral.btn_save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-------------------- dialogActiveProperty ---------------------->
    <v-dialog v-model="dialogActiveProperty" max-width="500px">
      <v-card tile>
        <div class="header-dialog">
          {{ $t("SigninGeneral.voucher_active") }}
          <v-spacer></v-spacer>
          <v-icon small @click="dialogActiveProperty = false">mdi-close</v-icon>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p>{{ $t("SigninGeneral.text_want_active") }}</p>
              <v-radio-group row v-model="voucherStatus" color="#1e9ff2">
                <v-radio value="true" color="#1e9ff2">
                  <template v-slot:label>
                    <div class="mt-2">{{ $t("SigninGeneral.yes") }}</div>
                  </template>
                </v-radio>
                <v-radio value="false" color="#1e9ff2">
                  <template v-slot:label>
                    <div class="mt-2">{{ $t("SigninGeneral.no") }}</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="footer-dialog pt-4 pb-4">
          <v-btn color="#6b6f82" outlined @click="dialogActiveProperty = false">{{
            $t("SigninGeneral.btn_cancel")
          }}</v-btn>
          <v-btn color="#666ee8" outlined @click="updateActiveStatusVoucher">
            {{ $t("SigninGeneral.btn_save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- </div> -->
  </v-container>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import { isThisMinute } from "date-fns";

export default {
  name: "SigninGeneralInfoScreen",
  metaInfo: {
    title: "General Info",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        // this.getGeneralinfo();
        next(false);
      }
    } else next();
  },
  data() {
    return {
      userToken: "",
      propertyID: "",
      generalInfo: {},
      contact: {},
      location: {},
      itemsCountry: [
        {
          _id: 0,
          name: "ไทย",
          value: "th",
        },
      ],
      itemsProvince: [],
      itemsAmphoe: [],
      itemsDistrict: [],
      dialogPropertyName: false,
      dialogPropertyAddress: false,
      dialogPropertyLocation: false,
      dialogSuspendProperty: false,
      dialogActiveProperty: false,
      propertyName: "",
      address_line_1: "",
      address_line_2: "",
      subdistrict: "",
      district: "",
      province: "",
      post_code: "",
      country: "",
      propertyLocation: {
        latitude: null,
        longitude: null,
      },
      reasons: ["-", this.$t("SigninGeneral.room_not_available")],
      voucherReason: "",
      voucherStatus: "true",
      professional_business: "false",
      alertProfessional: false,
      alertAboutProperty: false,
      alertChanel: false,
      typeAlert: "",
      colorAlert: "",
      iconAlert: "",
      msgAlert: "",
      about_property: "",
      channel_manager: "",
      channel_manager_check: false,
      pms: "",
      pms_check: false,
      center: { lat: 0, lng: 0 },
      markers: [],
      newlat: "",
      newlng: "",
      places: [],
      currentPlace: null,
      mapOptions: {},
      warndatalocation1: false,
      warndatasubdistrict: false,
      warndatadistrict: false,
      warndataprovince: false,
      warndatapostcode: false,
      warndatacountry: false,
      value_element: "",
      oldData: {},
      active: null,
      loadingGeneralData: false,
      loadingHotelStatus: false,
    };
  },
  computed: {
    google: gmapApi,
  },
  created() {
    this.userToken = this.$route.params.userToken;
    this.propertyID = this.$route.params.propertyId;
    this.initCountry();
    this.getGeneralinfo();
  },
  mounted() {},
  methods: {
    setlatandlng(markers) {
      this.propertyLocation.latitude = markers[0].position.lat;
      this.propertyLocation.longitude = markers[0].position.lng;
    },
    selectarea({ latLng }) {
      this.markers = [];
      this.markers = [{ position: { lat: latLng.lat(), lng: latLng.lng() } }];
      // this.newlat = this.markers[0].position.lat;
      // this.newlng = this.markers[0].position.lng;
      this.propertyLocation.latitude = this.markers[0].position.lat;
      this.propertyLocation.longitude = this.markers[0].position.lng;
    },
    gMapFunc({ latLng }) {
      this.markers = [];
      this.markers = [{ position: { lat: latLng.lat(), lng: latLng.lng() } }];
      this.propertyLocation.latitude = this.markers[0].position.lat;
      this.propertyLocation.longitude = this.markers[0].position.lng;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    searchArea() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.propertyLocation.longitude = marker.lng;
        this.propertyLocation.latitude = marker.lat;
        this.markers.push({ position: marker });
        this.center = marker;
        this.places.push(this.currentPlace);
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    async initCountry() {
      const self = this;
      var tempCountry = [];
      var temp = [];
      self.loadingCountry = true;
      self.loadingProvince = true;
      self.itemsCountry = [];
      self.itemsProvince = [];
      self.itemsAmphoe = [];
      self.itemsDistrict = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + `/setting/country`, {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || [],
              });
              if (el?.province?.length) {
                el?.province?.map((ele) => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || "",
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsCountry = tempCountry;
        self.itemsProvince = temp;
        // console.log("ประเทศ =", self.itemsCountry);
        // console.log("จังหวัด =", self.itemsProvince);
        // self.checkHaveData()
        // self.checkValueCountry()
      }
    },
    async getGeneralinfo() {
      var temp = null;
      this.loadingGeneralData = true;
      this.loadingHotelStatus = true;
      EventBus.$emit("loadingtillend");
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${this?.propertyID || this?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                this?.userToken ||
                localStorage?.getItem("Token") ||
                this?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          // console.log("general info :", temp);
          this.oldData = temp;
          this.generalInfo = temp;
          this.contact = temp.contact;
          this.location = temp?.contact?.location || {};
          this.professional_business =
            temp.professional_business === true ? "true" : "false";
          this.about_property = temp.about_property;
          this.channel_manager = temp.channel_manager;
          this.channel_manager_check = temp.channel_manager === null ? true : false;
          this.pms = temp.pms;
          this.pms_check = temp.pms === null ? true : false;
        }
      } catch (error) {
        this.loadingGeneralData = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        this.loadingGeneralData = false;
        setTimeout(() => {
          this.loadingHotelStatus = false;
        }, 500);
        EventBus.$emit("endloading");
        EventBus.$emit("refreshAppbarProperty");
      }
    },
    editPropertyName(value) {
      this.dialogPropertyName = true;
      this.propertyName = value;
    },
    editPropertyAddress(value) {
      console.log(value);
      this.dialogPropertyAddress = true;
      this.address_line_1 = value.address_line_1;
      this.address_line_2 = value.address_line_2;
      const tempCountry = this.itemsCountry.find(
        (p) =>
          p.name == value.country || value.country == "ไทย" || value.country == "Thailand"
      );
      this.country = tempCountry?._id || value.country;
      const tempProvince = this.itemsProvince.find(
        (p) =>
          p.province == value.province &&
          p?.amphoe == value.district &&
          p?.district == value.subdistrict
      );
      this.province = tempProvince?.province_code || value.province;
      this.district = tempProvince?.amphoe_code || value.district;
      this.subdistrict = tempProvince?.district_code || value.subdistrict;
      this.post_code = value?.post_code || tempProvince?.zipcode;
    },
    editPropertyLocation(lat, lng) {
      this.dialogPropertyLocation = true;
      this.propertyLocation = {
        latitude: lat,
        longitude: lng,
      };
      this.center = {
        lat: this.propertyLocation.latitude,
        lng: this.propertyLocation.longitude,
      };
      this.markers = [{ position: this.center }];
      // console.log(this.center);
      // this.markers.push({ position: this.center });
      // console.log(this.markers);
      // if (this.markers?.length > 1)
      //   this.markers = this.markers[this.markers.length - 1];
    },
    async updateProperty(action) {
      var updateInfo = {};
      var canupdate = false;

      var data = "";

      var eleLocation1 = document.getElementById("location1");
      var eleSubdistrict = document.getElementById("subdistrict");
      var eleDistrict = document.getElementById("district");
      var eleProvince = document.getElementById("province");
      var elePostcode = document.getElementById("postcode");
      var eleCountry = document.getElementById("country");

      if (action === "name") {
        updateInfo = {
          name: this.propertyName,
          property_detail: { property_name: this.propertyName },
        };
        this.dialogPropertyName = false;
        canupdate = true;
        this.data = "เปลี่ยนชื่อโรงแรมเป็น " + this.propertyName + " จากเดิม " + this.generalInfo.name;
      } else if (action === "address") {
        if (!this.address_line_1) {
          this.warndatalocation1 = true;
          eleLocation1.classList.add("border-pink-focus");
        } else {
          this.warndatalocation1 = false;
          eleLocation1?.classList?.remove("border-pink-focus");
        }
        if (!this.subdistrict) {
          this.warndatasubdistrict = true;
          eleSubdistrict.classList.add("border-pink-focus");
        } else {
          this.warndatasubdistrict = false;
          eleSubdistrict?.classList?.remove("border-pink-focus");
        }
        if (!this.district) {
          this.warndatadistrict = true;
          eleDistrict.classList.add("border-pink-focus");
        } else {
          this.warndatadistrict = false;
          eleDistrict?.classList?.remove("border-pink-focus");
        }
        if (!this.province) {
          this.warndataprovince = true;
          eleProvince.classList.add("border-pink-focus");
        } else {
          this.warndataprovince = false;
          eleProvince?.classList?.remove("border-pink-focus");
        }
        if (!this.post_code) {
          this.warndatapostcode = true;
          elePostcode.classList.add("border-pink-focus");
        } else {
          this.warndatapostcode = false;
          elePostcode?.classList?.remove("border-pink-focus");
        }
        if (!this.country) {
          this.warndatacountry = true;
          eleCountry.classList.add("border-pink-focus");
        } else {
          this.warndatacountry = false;
          eleCountry?.classList?.remove("border-pink-focus");
        }

        if (
          this.address_line_1 &&
          this.subdistrict &&
          this.district &&
          this.province &&
          this.post_code &&
          this.country
        ) {
          updateInfo = {
            contact: {
              address_line_1: this.address_line_1,
              address_line_2: this.address_line_2,
              subdistrict: this.subdistrict,
              district: this.district,
              province: this.province,
              post_code: this.post_code,
              country: this.country,
            },
          };
          this.dialogPropertyAddress = false;
          canupdate = true;
        } else {
          canupdate = false;
        }

        const tempCountry = this.itemsCountry.find((p) => p._id == this.country);
        if (tempCountry.name != this.contact.country) {
          this.data +=
            " เปลี่ยนประเทศเป็น " +
            tempCountry.name +
            " จากเดิม " +
            this.contact.country;
        }

        if (this.address_line_1 != this.contact.address_line_1) {
          this.data +=
            " เปลี่ยนที่อยู่ 1 เป็น " +
            this.address_line_1 +
            " จากเดิม " +
            this.contact.address_line_1;
        } 
        // if (this.address_line_2 != this.contact.address_line_2) {
        //   this.data +=
        //     " เปลี่ยนที่อยู่ 2 เป็น " +
        //     this.address_line_2 +
        //     " จากเดิม " +
        //     this.contact.address_line_2;
        // }

        const tempSubdistrict = this.itemsDistrict.find((p) => p.district_code == this.subdistrict);
        if (tempSubdistrict.district != this.contact.subdistrict) {
          this.data +=
            " เปลี่ยนตำบลเป็น " +
            tempSubdistrict.district +
            " จากเดิม " +
            this.contact.subdistrict;
        }

        const tempDistrict = this.itemsAmphoe.find((p) => p.amphoe_code == this.district);
        if (tempSubdistrict.amphoe != this.contact.district) {
          this.data +=
            " เปลี่ยนอำเภอเป็น " +
            tempDistrict.amphoe +
            " จากเดิม " +
            this.contact.district;
        }

        const tempProvince = this.itemsProvince.find((p) => p.province_code == this.province);
        if (tempProvince.province != this.contact.province) {
          this.data +=
            " เปลี่ยนจังหวัดเป็น " +
            tempProvince.province +
            " จากเดิม " +
            this.contact.province;
        }

        if (this.post_code != this.contact.post_code) {
          this.data +=
            " เปลี่ยนรหัสไปรษณีย์เป็น " +
            this.post_code +
            " จากเดิม " +
            this.contact.post_code;
        }

      } else if (action === "location") {
        updateInfo = {
          contact: {
            location: {
              latitude: this.propertyLocation.latitude,
              longitude: this.propertyLocation.longitude,
            },
          },
        };
        this.dialogPropertyLocation = false;
        canupdate = true;

       if (this.propertyLocation.latitude != this.location.latitude) {
          this.data +=
            " เปลี่ยนพิกัดโรงแรมเป็น " +
            this.propertyLocation.latitude +
            " จากเดิม " +
            this.location.latitude;
        }
        if (this.propertyLocation.longitude != this.location.longitude) {
          this.data +=
            " เปลี่ยนพิกัดโรงแรมเป็น " +
            this.propertyLocation.longitude +
            " จากเดิม " +
            this.location.longitude;
        }
      } else if (action === "suspend") {
        updateInfo = {
          active: this.voucherStatus === "true" ? true : false,
          closed_reason: this.voucherReason,
        };
        this.dialogSuspendProperty = false;
        canupdate = true;
      }
      // console.log("info = ", updateInfo);
      if (canupdate) {
        EventBus.$emit("loadingtillend");
        try {
          const res = await this.axios.put(
            process.env.VUE_APP_API +
              "/property/general_info?property_id=" +
              this.propertyID,
            updateInfo,
            {
              headers: {
                Authorization: this.userToken || localStorage?.getItem("Token"),
                "X-Language-Code":
                  localStorage?.getItem("language") || this?.$store?.state?.language,
              },
            }
          );
          // console.log("res: ", res);
          if (res.status == 200) {
            // console.log(res?.data?.message);
            if (this.data != null) {
            await this.axios.post(
              process.env.VUE_APP_AIGENT_API + "/logs/save",
              {
                properties_id: this.propertyID,
                data: this.data,
                type: "information",
              },
            );
            }
            this.getGeneralinfo();
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
        } finally {
          EventBus.$emit("endloading");
        }
      }
    },
    // -----
    async updateProfessional(value) {
      var updateInfo = {
        professional_business: value === "true" ? true : false,
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await this.axios.put(
          process.env.VUE_APP_API +
            "/property/general_info?property_id=" +
            this.propertyID,
          updateInfo,
          {
            headers: {
              Authorization: this.userToken || localStorage?.getItem("Token"),
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.message);
          this.alertProfessional = true;
          this.typeAlert = "succcess";
          this.colorAlert = "#5fe0b2";
          this.iconAlert = "mdi-check-circle-outline";
          this.msgAlert = this.$t("SigninGeneral.msg_update_success");
          this.getGeneralinfo();
          setTimeout(() => {
            this.alertProfessional = false;
          }, 3000);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
      }
    },
    async updateAboutProperty(value) {
      var updateInfo = {
        about_property: value,
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await this.axios.put(
          process.env.VUE_APP_API +
            "/property/general_info?property_id=" +
            this.propertyID,
          updateInfo,
          {
            headers: {
              Authorization: this.userToken || localStorage?.getItem("Token"),
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.message);
          this.alertAboutProperty = true;
          this.typeAlert = "succcess";
          this.colorAlert = "#5fe0b2";
          this.iconAlert = "mdi-check-circle-outline";
          this.msgAlert = this.$t("SigninGeneral.msg_update_success");
          this.getGeneralinfo();
          setTimeout(() => {
            this.alertAboutProperty = false;
          }, 3000);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
      }
    },
    checkPMS() {
      // this.pms_check = !this.pms_check;
      this.pms = null;
    },
    checkChanelManager() {
      // this.channel_manager_check = !this.channel_manager_check;
      this.channel_manager = null;
    },
    resetChannel() {
      this.pms = null;
      this.pms_check = true;
      this.channel_manager = null;
      this.channel_manager_check = true;
    },
    async updateChannel() {
      var updateInfo = {
        pms: this.pms,
        channel_manager: this.channel_manager,
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await this.axios.put(
          process.env.VUE_APP_API +
            "/property/general_info?property_id=" +
            this.propertyID,
          updateInfo,
          {
            headers: {
              Authorization: this.userToken || localStorage?.getItem("Token"),
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.message);
          this.alertChanel = true;
          this.typeAlert = "succcess";
          this.colorAlert = "#5fe0b2";
          this.iconAlert = "mdi-check-circle-outline";
          this.msgAlert = this.$t("SigninGeneral.msg_update_success");
          this.getGeneralinfo();
          setTimeout(() => {
            this.alertChanel = false;
          }, 3000);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
      }
    },
    checkHaveData() {
      const self = this;
      const tempCountry = self?.itemsCountry?.find(
        (element) => element.name === self.country
      );
      if (tempCountry?.name) self.country = tempCountry?.value;
      if (tempCountry?.province?.length) {
        const tempDistrict = tempCountry?.province?.find(
          (element) =>
            element.province === self.aprovince &&
            element.district === self.subdistrict &&
            element.subdistrict === self.district
        );
        if (tempDistrict?.province) self.province = tempDistrict?.province_code;
        if (tempDistrict?.district) self.subdistrict = tempDistrict?.district_code;
        if (tempDistrict?.subdistrict) self.district = tempDistrict?.subdistrict_code;
        if (tempDistrict?.zipcode) self.post_code = tempDistrict?.zipcode;
      }
    },
    selectProvinceFunc() {
      const self = this;
      var temp = [];
      self?.itemsProvince?.map((el) => {
        if (
          el?.province_code === self.province &&
          self.province &&
          self.province !== undefined
        ) {
          temp.push(el);
        }
      });
      self.loadingAmphoe = false;
      self.itemsAmphoe = temp;
    },
    selectAmphoeFunc() {
      const self = this;
      self.loadingDistrict = true;
      var temp = [];
      self?.itemsAmphoe?.map((el) => {
        if (
          el.province_code === self.province &&
          el.amphoe_code === self.district &&
          self.province &&
          self.subdistrict
        ) {
          temp.push(el);
        }
      });
      self.loadingDistrict = false;
      self.itemsDistrict = temp;
    },
    selectPostcodeFunc() {
      const self = this;
      var temp = {};
      self?.itemsProvince?.map((el) => {
        if (
          el.province_code === self.province &&
          el.amphoe_code === self.district &&
          el.district_code === self.subdistrict &&
          self.province &&
          self.subdistrict &&
          self.district
        ) {
          temp = el;
        }
      });
      // self.loadingZipCode = false
      if (temp.zipcode) self.post_code = temp.zipcode;
    },
    delProperty() {
      const self = this;
      swal(self.$t("Alert.qtneedtoremove"), {
        dangerMode: true,
        buttons: {
          cancel: self.$t("Alert.btn_no"),
          confirm: {
            text: self.$t("Alert.btn_yes"),
            value: "confirm",
          },
        },
      }).then(async (value) => {
        if (value === "confirm") {
          EventBus.$emit("loadingtillend");
          try {
            const res = await self.axios.delete(
              process.env.VUE_APP_API + `/property?property_id=${self.propertyID}`,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
            if (res?.status == 200) {
              const itemLink = {
                link: "propertydetail",
                name: "SignupPropertyDetailScreen",
                params: {
                  userToken: self.userToken,
                  propertyId: self?.propertyData?._id,
                },
              };
              EventBus.$emit("changePathname", itemLink);
            } else {
              swal(
                self.$t("Alert.warn_title"),
                res?.data?.message || "Please try again",
                self.$t("Alert.warn_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            }
          } catch (error) {
            EventBus.$emit("endloading");
            console.log(error?.response?.data?.message || error);
            swal(
              self.$t("Alert.warn_title"),
              error?.response?.data?.message || "Please try again",
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        }
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-blue-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.add("border-blue-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.remove("border-blue-focus");
        this.value_element = "";
      }
    },
    checkChangeData() {
      const self = this;
      var cantleavethispage = false;
      const tmpProfessional =
        self.oldData.professional_business === true ? "true" : "false";
      const tmpCMCheck = self.oldData.channel_manager === null ? true : false;
      const tmpPMCheck = self.oldData.pms === null ? true : false;
      // console.log(
      //   self.oldData,
      //   self.generalInfo.name !== self.oldData.name,
      //   self.contact.address_line_1 !== self.oldData.contact.address_line_1,
      //   self.contact.address_line_2 !== self.oldData.contact.address_line_2,
      //   self.contact.subdistrict !== self.oldData.contact.subdistrict,
      //   self.contact.district !== self.oldData.contact.district,
      //   self.contact.province !== self.oldData.contact.province,
      //   self.contact.country !== self.oldData.contact.country,
      //   self.contact.post_code !== self.oldData.contact.post_code,
      //   self.location.latitude !== self.oldData.contact.location.latitude,
      //   self.location.longitude !== self.oldData.contact.location.longitude,
      //   self.professional_business !== tmpProfessional,
      //   self.about_property, self.oldData.about_property, self.about_property !== self.oldData.about_property,
      //   self.channel_manager !== self.oldData.channel_manager,
      //   self.channel_manager_check !== tmpCMCheck,
      //   self.pms !== self.oldData.pms,
      //   self.pms_check !== tmpPMCheck
      // );
      if (
        (self.generalInfo.name && self.generalInfo.name !== self.oldData.name) ||
        (self.contact.address_line_1 &&
          self.contact.address_line_1 !== self.oldData.contact.address_line_1) ||
        (self.contact.address_line_2 &&
          self.contact.address_line_2 !== self.oldData.contact.address_line_2) ||
        (self.contact.subdistrict &&
          self.contact.subdistrict !== self.oldData.contact.subdistrict) ||
        (self.contact.district &&
          self.contact.district !== self.oldData.contact.district) ||
        (self.contact.province &&
          self.contact.province !== self.oldData.contact.province) ||
        (self.contact.country && self.contact.country !== self.oldData.contact.country) ||
        (self.contact.post_code &&
          self.contact.post_code !== self.oldData.contact.post_code) ||
        (self.location.latitude &&
          self.location.latitude !== self.oldData.contact.location.latitude) ||
        (self.location.longitude &&
          self.location.longitude !== self.oldData.contact.location.longitude) ||
        (self.professional_business && self.professional_business !== tmpProfessional) ||
        (self.about_property && self.about_property !== self.oldData.about_property) ||
        (self.channel_manager && self.channel_manager !== self.oldData.channel_manager) ||
        (self.channel_manager_check && self.channel_manager_check !== tmpCMCheck) ||
        (self.pms && self.pms !== self.oldData.pms) ||
        (self.pms_check && self.pms_check !== tmpPMCheck)
      ) {
        cantleavethispage = true;
      } else cantleavethispage = false;
      return cantleavethispage;
    },
    InactiveVoucher(reason, status) {
      // console.log(reason);
      // console.log(status);
      this.dialogSuspendProperty = true;
      this.voucherStatus = status === true ? "false" : "true";
      this.voucherReason = !reason ? "-" : reason;
    },
    async updateStatusVoucher() {
      this.dialogSuspendProperty = false;
      var updateInfo = {
        voucher_status: this.voucherStatus === "true" ? true : false,
        closed_reason: this.voucherReason,
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await this.axios.put(
          process.env.VUE_APP_API +
            "/property/general_info?property_id=" +
            this.propertyID,
          updateInfo,
          {
            headers: {
              Authorization: this.userToken || localStorage?.getItem("Token"),
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.message);
          this.alertChanel = true;
          this.typeAlert = "succcess";
          this.colorAlert = "#5fe0b2";
          this.iconAlert = "mdi-check-circle-outline";
          this.msgAlert = this.$t("SigninGeneral.msg_update_success");
          this.getGeneralinfo();
          setTimeout(() => {
            this.alertChanel = false;
          }, 3000);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
      }
    },
    ActiveVoucher(status) {
      // console.log('status: ', status)
      this.dialogActiveProperty = true;
      this.voucherStatus = !status ? "true" : "false";
    },
    async updateActiveStatusVoucher() {
      this.dialogActiveProperty = false;
      var updateInfo = {
        voucher_status: this.voucherStatus === "true" ? true : false,
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await this.axios.put(
          process.env.VUE_APP_API +
            "/property/general_info?property_id=" +
            this.propertyID,
          updateInfo,
          {
            headers: {
              Authorization: this.userToken || localStorage?.getItem("Token"),
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.message);
          this.alertChanel = true;
          this.typeAlert = "succcess";
          this.colorAlert = "#5fe0b2";
          this.iconAlert = "mdi-check-circle-outline";
          this.msgAlert = this.$t("SigninGeneral.msg_update_success");
          this.getGeneralinfo();
          setTimeout(() => {
            this.alertChanel = false;
          }, 3000);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        this.voucherStatus = null;
      }
    },
    checkHotelStatusFunc() {
      // this.$t("SigninGeneral.status_pending_approve")
      var tmpStatus = "";
      // console.log("general info: ", this.generalInfo);
      if (
        this.generalInfo.property_status == "completed" &&
        this.generalInfo.active &&
        this.generalInfo.voucher_status &&
        !this.generalInfo.voucher_suspend
      )
        tmpStatus = this.$t("SigninGeneral.status_go_live");
      else if (
        this.generalInfo.property_status == "completed" &&
        this.generalInfo.active &&
        !this.generalInfo.voucher_status &&
        !this.generalInfo.voucher_suspend
      )
        tmpStatus = this.$t("SigninGeneral.status_temporary_closed");
      else if (
        this.generalInfo.property_status == "completed" &&
        this.generalInfo.active &&
        this.generalInfo.voucher_suspend
      )
        tmpStatus = this.$t("SigninGeneral.status_locked");
      else tmpStatus = this.$t("SigninGeneral.status_pending_approved");
      return tmpStatus;
    },
  },
  watch: {
    channel_manager() {
      if (this.channel_manager === "" || this.pms === null)
        this.channel_manager_check = true;
      else this.channel_manager_check = false;
    },
    pms() {
      // if (this.pms === "" || this.pms === null) this.pms_check = true;
      // else this.pms_check = false;
    },
    province() {
      this.selectProvinceFunc();
      this.checkHaveData();
    },
    district() {
      this.selectAmphoeFunc();
      this.checkHaveData();
    },
    subdistrict() {
      this.selectPostcodeFunc();
      this.checkHaveData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.inputarea {
  width: 100%;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 0px 10px 10px;
  font-size: 16px;
  height: 36px;
}
.inputarea:focus {
  width: 100%;
  border: 1px solid #e83e8c;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 0px 10px 10px;
  font-size: 16px;
  height: 36px;
}

.text-primary {
  color: #666ee8 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #414bd3 !important;
}

.topic {
  margin-top: 0px;
  font-size: 16px;
}

.detail {
  margin: 0px 0px;
}

.value {
  font-size: 16px;
  margin: 0;
  line-height: 1.4;
  font-style: normal;
  color: #111111;
}

.link-btn {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

.space-divider {
  margin-top: -8px;
  margin-bottom: -8px;
}

.badge-success {
  background-color: #28d094;
  // font-family: 'Prompt', sans-serif !important;
}

.badge-danger {
  background-color: #ff4961;
  // font-family: 'Prompt', sans-serif !important;
}

.badge-warning {
  background-color: #ff9149;
  color: #fff;
}

.header-dialog {
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #626e82;
  margin-bottom: 10px;
}

.footer-dialog {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #626e82;
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
  margin-top: -5px;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.border-blue-focus {
  border-color: #1e9ff2 !important;
  height: 2.5rem !important;
}
</style>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
</style>
